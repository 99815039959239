import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from './colors'

export const LogoStyle = styled.img`
    height: 140px !important;
`

export const HeaderName = styled.text`
    font-size: 50px;
    font-weight: bold;
`

export const MenuContainer = styled.nav`
    align-items: center;
    background-color: ${colors.greenPrimaryLight};
    font-size: 25px;
    color: ${colors.bluePrimaryDark};
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
`

export const MenuContainerToDelete = styled.nav`
    margin-top: 0px;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
`

export const StyledLink = styled(Link)`
    font-size: 25px;
    font-weight: bold;
    padding: 0px 15px;
    text-decoration: none;
    text-align: center;
    color: ${colors.bluePrimaryDark};
`

export const StyledA = styled.a`
    font-size: 25px;
    font-weight: bold;
    text-decoration: undelined;
    text-align: right;
    color: ${colors.bluePrimaryDark};
`

/*
export const HeaderContainer = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 58px;
    padding-left: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.bluePrimaryDark};
    background-color: white;
`

export const HeaderAddress = styled.text`
    font-size: 30px;
`

export const RightPaneContainer = styled.div`
    padding: 40px;
    margin-right: 30px;
    margin-left: 20px;
    color: ${colors.bluePrimaryDark};
`
*/
