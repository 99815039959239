import styled from 'styled-components'
import colors from './colors'

export const StyledH1 = styled.h1`
	font-size: 60px;
	font-weight: bold;
`

export const StyledH2 = styled.h2`
	font-size: 40px;
	font-weight: bold;
`
export const StyledH3 = styled.h3`
	font-size: 30px;
	font-weight: bold;
`

export const StyledH4 = styled.h4`
	font-size: 25px;
	font-weight: bold;
	whitespace: pre;
`

export const StyledH5 = styled.h5`
	font-size: 20px;
	font-weight: bold;
`

export const CarousselImage = styled.img`
	width: 100%;
	border-radius: 25px;
`

export const StyledTextCentered = styled.div`
	font-size: 30px;
	display: flex;
	justify-content: center;
	color: ${colors.bluePrimaryDark};
`

export const TextBackgroundContainer = styled.div`
	background-color: ${colors.greenPrimaryLight};
	box-shadow: 12px 18px 15px ${colors.grayPrimary};
	border-radius: 25px;
	padding: 30px;
	color: ${colors.bluePrimaryDark};
	margin-right: 20px;
	top: 20px;
`

export const Centered = styled.div`
	display: flex;
	justify-content: center;
`

/*
export const BigLogoStyle = styled.img`
    width: 100%;
    max-width: 1800px;
    border-radius: 25px;
`

export const BigLogoStyleRadius = styled.img`
    height: 500px !important;
    box-shadow: 12px 18px 15px ${colors.grayPrimary};
    border-radius: 25px;
`

export const CroppedLogoStyle = styled.img`
    height: 270px;
    object-fit: cover;
    border-radius: 25px;
`

export const ImageTextContainer = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.bluePrimaryDark};
    width: 100%;
`

export const CarousselContainer = styled.div`
    margin-top: 10px;
    width: 100%;
`

export const TextOnImageContainer = styled.div`
    position: relative;
    text-align: center;
    color: white;
`

export const TextContainer = styled.div`
    padding: 40px;
    margin-right: 30px;
    margin-left: 20px;
    color: ${colors.bluePrimaryDark};
`

export const CenteredFloat = styled.h1`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const StyledText = styled.text`
    font-size: 30px;
`
*/
