import { FooterContainer } from '../utils/styles/FooterStyles'

const Footer = () => {
	return (
		<FooterContainer>
			Copyright © 2021 Clinique Vétérinaire de Varennes - Tous droits
			réservés.
		</FooterContainer>
	)
}

export default Footer
