import styled from 'styled-components'
import colors from './colors'

export const FooterContainer = styled.nav`
    padding: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: ${colors.bluePrimaryDark};
    font-size: 20px;
`
