import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.webp'
import { HeaderName, LogoStyle, MenuContainer, StyledA, StyledLink } from '../utils/styles/HeaderStyles'
import { StyledH3, StyledH4, StyledH5 } from '../utils/styles/PageStyles'

const Header = () => {
    return (
        <>
            <Row style={{ backgroundColor: 'white' }}>
                <Col xs={12} md={3}>
                    <Link to="/">
                        <LogoStyle src={Logo} />
                    </Link>
                </Col>
                <Col xs={12} md={6} className="centered">
                    <HeaderName>Clinique Vétérinaire Varennes</HeaderName>
                    <StyledH4>2085 Boul. Marie-Victorin, local 101, Varennes, J3X 0L5</StyledH4>
                </Col>
                <Col xs={12} md={3} className="alignRight" style={{ marginTop: '2%' }}>
                    <StyledH4>Ouvert du lundi au samedi</StyledH4>
                    <StyledH3>(450) 652-6066</StyledH3>
                    <StyledH5>info@veterinairevarennes.com</StyledH5>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} className="menuCol">
                    <MenuContainer>
                        <Row style={{ justifyContent: 'space-between' }}>
                            <Col>
                                <StyledLink to="/accueil">Accueil</StyledLink>
                                <StyledLink className="text-nowrap" to="/services">
                                    Nos services
                                </StyledLink>
                            </Col>
                            <Col className="alignRight">
                                <StyledA
                                    className="text-nowrap"
                                    href="https://www.mavitrineveterinaire.ca/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Ma vitrine vétérinaire
                                </StyledA>
                            </Col>
                        </Row>
                    </MenuContainer>
                </Col>
            </Row>
        </>
    )
}

export default Header
