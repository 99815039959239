const colors = {
    greenPrimary: '#5cb3b1',
    greenPrimaryLight: '#8dcac8',
    greenPrimaryXLight: '#def0ef',
    bluePrimary: '#3273a4',
    bluePrimaryDark: '#2d6895',
    grayPrimary: '#667e88',
    grayPrimaryLight: '#d1d8db',
}

export default colors
