import { Container } from 'react-bootstrap'
import styled, { createGlobalStyle } from 'styled-components'
import colors from './colors'

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: "Lobster Cabin";
    }

    body {
		margin: 0;
		font-family:  "Lobster Cabin"
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: ${colors.greenPrimaryXLight};
    }
`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle

export const CustomContainer = styled(Container)`
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
    color: ${colors.bluePrimaryDark};
`
