import { Col, Row } from 'react-bootstrap'
import { StyledH1, StyledTextCentered } from '../../utils/styles/PageStyles'
import ServicesRow from '../../components/ServicesRow'
import Consultation from '../../assets/consultation.png'
import Chirurgie from '../../assets/chirurgie.png'
import Dentisterie from '../../assets/dentisterie.png'
import Vaccination from '../../assets/vaccination.png'
import Laboratoire from '../../assets/laboratoire.png'
import Radiographie from '../../assets/radiographie.png'
import Prevention from '../../assets/prevention.png'
import Pharmacie from '../../assets/pharmacie.png'
import Pension from '../../assets/pension.png'
import Nutrition from '../../assets/nutrition.png'
import Rasage from '../../assets/rasage.png'

const Services = () => {
    return (
        <>
            <Row>
                <Col sm={12} md={12}>
                    <StyledTextCentered>
                        <StyledH1 className="dancingScript">Nos services</StyledH1>
                    </StyledTextCentered>
                </Col>
            </Row>
            <ServicesRow
                texOnRight={false}
                imageObj={Consultation}
                boxTitle={'Consultation'}
                boxP1={
                    'Nous offrons des consultations personnalisés selon les besoins de chaque patient pour des problèmes de médicine générale, médicine interne, gériatrie, dermatologie, neurologie.'
                }
                boxP2={
                    'Durant ces consultations, il nous fera plaisir de répondre à toutes vos questions et inquiétudes.'
                }
            />
            <ServicesRow
                texOnRight={true}
                imageObj={Chirurgie}
                boxTitle={'Chirurgie'}
                boxP1={
                    "Nous offrons un service de chirurgie, qui inclue des chirurgie électives, comme la castration des mâles et l'ovariohysterectomie des femelles, ainsi que des chirurgie cutanée ( pour des plaies et masses cutanées), des chirurgies intra-abdominales sur les tractus digestif ( comme pour un corps étranger par exemple), la vessie , et certaines chirurgies orthopédiques."
                }
            />
            <ServicesRow
                texOnRight={false}
                imageObj={Dentisterie}
                boxTitle={'Dentisterie'}
                boxP1={
                    'Les problèmes de dentition des nos patients nous tiennent à cœur. Le service de dentisterie est un traitement qui consiste à maintenir la santé dentaire de votre animal le plus longtemps possible.'
                }
                boxP2={'Ce service comprend des détartrages et des extractions dentaires.'}
            />
            <ServicesRow
                texOnRight={true}
                imageObj={Vaccination}
                boxTitle={'Vaccination'}
                boxP1={
                    'Nous offrons également un service de vaccination pour chat et chien. Notre équipe évaluera et vous recommandera les vaccins pertinents selon le mode de vie de votre animal.'
                }
                boxP2={
                    "Lors de ces examens, l'implantation d'une micropuce peut être réalisée en même temps que les vaccins."
                }
            />
            <ServicesRow
                texOnRight={false}
                imageObj={Laboratoire}
                boxTitle={'Laboratoire sur place'}
                boxP1={'Nous effectuons un grand éventail d’analyses sanguines et d’urine.'}
                boxP2={
                    "Une bonne partie des ces analyses sont effectuées sur place et d'autres, plus spécifiques, au laboratoire externe."
                }
            />
            <ServicesRow
                texOnRight={true}
                imageObj={Radiographie}
                boxTitle={'Radiographie'}
                boxP1={'Nous offrons, sur place, un service de radiographies numériques.'}
            />
            <ServicesRow
                texOnRight={false}
                imageObj={Prevention}
                boxTitle={'Prévention des parasites'}
                boxP1={'Notre service de prévention des parasites sera discuté avec vous durant vos consultations.'}
                boxP2={'Nous serons en mesure de faire des recommandations basées sur le style de vie de l’animal.'}
            />
            <ServicesRow
                texOnRight={true}
                imageObj={Pharmacie}
                boxTitle={'Pharmacie vétérinaire'}
                boxP1={
                    'Les prescriptions du vétérinaire sont préparées sur place et notre équipe est toujours disponible pour vous expliquer le dosage, l’administration et pour répondre à toutes vos questions.'
                }
            />
            <ServicesRow
                texOnRight={false}
                imageObj={Pension}
                boxTitle={'Pension'}
                boxP1={
                    'Nous offrons un service de pension pour les chats. Nous offrons un service de pension pour les chats.'
                }
            />
            <ServicesRow
                texOnRight={true}
                imageObj={Nutrition}
                boxTitle={'Nutrition'}
                boxP1={
                    'Lors de votre consultation, notre équipe peut vous conseiller les meilleur aliments pour votre compagnon à quatre pattes.'
                }
            />
            <ServicesRow
                texOnRight={false}
                imageObj={Rasage}
                boxTitle={'Rasage pour chats'}
                boxP1={'Nous offrons le rasage pour les chats uniquement, avec ou sans sédation.'}
            />
        </>
    )
}

export default Services
