import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import GlobalStyle, { CustomContainer } from './utils/styles/GlobalStyle'
import Header from './pageFrame/Header'
import Accueil from './pages/Accueil'
import Footer from './pageFrame/Footer'
import './fonts/DancingScript-Regular.ttf'
import './utils/styles/customCss.css'
import { Col, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Services from './pages/Services'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<Router basename={'/'}>
			<>
				<GlobalStyle />
				<CustomContainer>
					<Row>
						<Col>
							<Header />
						</Col>
					</Row>
					<Row>
						<Col className="pageCol">
							<Routes>
								<Route exact path="/" element={<Accueil />} />
								<Route
									exact
									path="/accueil"
									element={<Accueil />}
								/>
								<Route
									path="/services"
									element={<Services />}
								/>
							</Routes>
						</Col>
					</Row>
					<Row>
						<Col>
							<Footer />
						</Col>
					</Row>
				</CustomContainer>
			</>
		</Router>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
