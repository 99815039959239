import {
	CarousselImage,
	Centered,
	StyledH1,
	StyledH2,
	StyledH3,
	StyledH4,
	StyledTextCentered,
	TextBackgroundContainer,
} from '../../utils/styles/PageStyles'
import Stetoscope from '../../assets/stetoscope.png'
import ChienChat from '../../assets/chienChat.jpg'
import { Carousel, Col, Row } from 'react-bootstrap'
import axios from 'axios'
import { useState } from 'react'

const Accueil = () => {
	const [greetingTitle, setGreetingTitle] = useState(undefined)
	const [greetingText, setGreetingText] = useState(undefined)

	axios
		.get(`${window.location.origin}/greetingTitle.txt`)
		.then(function (response) {
			setGreetingTitle(response.data)
		})

	axios
		.get(`${window.location.origin}/greetingText.txt`)
		.then(function (response) {
			setGreetingText(response.data)
		})

	return (
		<>
			<Row>
				<Col sm={12} md={12}>
					<StyledTextCentered>
						<StyledH1 className="dancingScript">
							Bienvenue sur notre site
						</StyledH1>
					</StyledTextCentered>
				</Col>
			</Row>
			{(greetingTitle || greetingText) && (
				<TextBackgroundContainer style={{ marginBottom: '25px' }}>
					{greetingTitle && (
						<Row>
							<Col sm={12} md={12}>
								<StyledTextCentered>
									<StyledH2 className="dancingScript">
										{greetingTitle}
									</StyledH2>
								</StyledTextCentered>
							</Col>
						</Row>
					)}
					{greetingText && (
						<Row>
							<Col sm={12} md={12}>
								<StyledTextCentered>
									<StyledH3 className="dancingScript">
										{greetingText}
									</StyledH3>
								</StyledTextCentered>
							</Col>
						</Row>
					)}
				</TextBackgroundContainer>
			)}
			<Row>
				<Col sm={12} md={6}>
					<Carousel fade nextLabel="" prevLabel="">
						<Carousel.Item>
							<CarousselImage src={ChienChat} />
						</Carousel.Item>
						<Carousel.Item>
							<CarousselImage src={Stetoscope} />
						</Carousel.Item>
					</Carousel>
				</Col>
				<Col sm={12} md={6}>
					<TextBackgroundContainer>
						<Centered>
							<StyledH2 className="dancingScript">
								Notre mission
							</StyledH2>
						</Centered>
						<Centered>
							<StyledH4>
								Depuis 30 ans, notre clinique familiale se donne
								comme mission le bien-être de nos patients à 4
								pattes et de leurs maîtres.
								<br />
								<br />
								Notre devise est aimer, soigner et guérir vos
								compagnons. Nous offrons un service de qualité
								selon vos besoins, que ce soit préventif ou
								curatif, nous les traiterons comme s'ils étaient
								nos propres animaux. <br />
								<br />
								Confiez la santé de vos amis si chères à notre
								vétérinaire expérimenté.
							</StyledH4>
						</Centered>
					</TextBackgroundContainer>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={12}>
					<Centered>
						<StyledH2
							className="dancingScript"
							style={{
								marginTop: '30px',
								marginBottom: '15px',
							}}>
							Heures d'ouverture
						</StyledH2>
					</Centered>
					<Centered>
						<StyledH4>
							Lundi : 9h à 20h
							<br />
							Mardi : 9h à 20h
							<br />
							Mercredi : 9h à 18h
							<br />
							Jeudi : 9h à 20h
							<br />
							Vendredi : 9h à 20h
							<br />
							Samedi : 9h à 13h
							<br />
							Dimanche : Fermé
						</StyledH4>
					</Centered>
				</Col>
			</Row>
		</>
	)
}

export default Accueil
