import { Col, Row } from 'react-bootstrap'
import { CarousselImage, Centered, StyledH2, StyledH4, TextBackgroundContainer } from '../utils/styles/PageStyles'

const ServicesRow = ({ texOnRight, imageObj, boxTitle, boxP1, boxP2 }) => {
    return (
        <Row className="servicesRows">
            {texOnRight ? (
                <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 5, order: 1 }}>
                    <CarousselImage src={imageObj} />
                </Col>
            ) : (
                ''
            )}
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 7, order: 2 }}>
                <TextBackgroundContainer>
                    <Centered>
                        <StyledH2 className="dancingScript">{boxTitle}</StyledH2>
                    </Centered>
                    <Centered>
                        <StyledH4>
                            <p>{boxP1}</p>
                            <p>{boxP2}</p>
                        </StyledH4>
                    </Centered>
                </TextBackgroundContainer>
            </Col>
            {!texOnRight ? (
                <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 5, order: 3 }}>
                    <CarousselImage src={imageObj} />
                </Col>
            ) : (
                ''
            )}
        </Row>
    )
}

export default ServicesRow
